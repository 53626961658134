<template>
  <ion-card>
    <ion-card-header v-if="showHeader">
      <div class="d-flex">
        <a
          :href="`leagues/${leagueId}/stats`"
          class="btn btn-secondary float-right d-flex align-items-center flex-grow-1 me-1"
        >
          <ion-icon :icon="time"></ion-icon>
          <div class="ms-2">Stats</div>
        </a>
        <a
          :href="`leagues/${leagueId}/history`"
          class="btn btn-secondary float-right d-flex align-items-center flex-grow-1 ms-1"
        >
          <ion-icon :icon="time"></ion-icon>
          <div class="ms-2">History</div>
        </a>
      </div>
    </ion-card-header>

    <ion-card-content>
      <div v-if="!hasRankings" class="mx-3 mb-3">
        <ion-icon :icon="radioButtonOff" />
        Loading...
      </div>
      <div v-else style="overflow-y: auto; max-height: 33vh">
        <table class="w-100" v-if="rankings.length">
          <thead v-if="showStats">
            <tr>
              <th>RANK</th>
              <th>NAME</th>
              <th
                class="text-center"
                data-toggle="tooltip"
                data-placement="top"
                title="Events"
              >
                E
              </th>
              <th
                class="text-center"
                data-toggle="tooltip"
                data-placement="top"
                title="1st Place Finishes"
              >
                1ST
              </th>
              <th
                class="text-center"
                data-toggle="tooltip"
                data-placement="top"
                title="2nd Place Finishes"
              >
                2ND
              </th>
              <th
                class="text-center"
                data-toggle="tooltip"
                data-placement="top"
                title="3rd Place Finishes"
              >
                3RD
              </th>
              <th
                class="text-center"
                data-toggle="tooltip"
                data-placement="top"
                title="Top 5 Finishes"
              >
                TOP 5
              </th>
              <th
                class="text-center"
                data-toggle="tooltip"
                data-placement="top"
                title="Top 10 Finishes"
              >
                TOP 10
              </th>
              <th
                class="text-center"
                data-toggle="tooltip"
                data-placement="top"
                title="Top 25 Finishes"
              >
                TOP 25
              </th>
              <th
                class="text-center"
                data-toggle="tooltip"
                data-placement="top"
                title="Missed Cut"
              >
                MC
              </th>
              <th
                class="text-center"
                data-toggle="tooltip"
                data-placement="top"
                title="No Pick"
              >
                NP
              </th>
              <th>WINNINGS</th>
            </tr>
          </thead>
          <tbody>
            <tr class="active" v-if="showStats && me">
              <td>{{ myPlace }}</td>
              <td>{{ me ? me.user.display_name : "" }}</td>
              <td>{{ formatCurrency(me.winnings) }}</td>
            </tr>
            <tr v-if="showStats">
              <td colspan="3" style="line-height: 0.5">&nbsp;</td>
            </tr>
            <tr
              v-for="(ranking, ndx) in rankings"
              :key="ndx"
              :class="{ active: ranking.user.id == userId }"
            >
              <td>{{ ndx + 1 }}</td>
              <td>{{ ranking.display_name }}</td>
              <td v-if="isUpgraded" class="text-center">
                {{ ranking.events }}
              </td>
              <td v-if="isUpgraded" class="text-center">
                {{ ranking.firsts }}
              </td>
              <td v-if="isUpgraded" class="text-center">
                {{ ranking.seconds }}
              </td>
              <td v-if="isUpgraded" class="text-center">
                {{ ranking.thirds }}
              </td>
              <td v-if="isUpgraded" class="text-center">
                {{ ranking.top_5s }}
              </td>
              <td v-if="isUpgraded" class="text-center">
                {{ ranking.top_10s }}
              </td>
              <td v-if="isUpgraded" class="text-center">
                {{ ranking.top_25s }}
              </td>
              <td v-if="isUpgraded" class="text-center">
                {{ ranking.missed_cuts }}
              </td>
              <td v-if="isUpgraded" class="text-center">
                {{ ranking.no_picks }}
              </td>
              <td
                v-if="!isUpgraded && showStats"
                colspan="8"
                class="text-center"
              >
                <div v-if="ndx === 0" class="p-3 m-3 bg-white rounded">
                  <p class="lead mb-0">Want to see more stats?</p>
                  <p class="mb-2">
                    <small
                      >Become a Teepster member for many more benefits</small
                    >
                  </p>
                  <a :href="learnUrl" class="btn btn-link btn-sm">Learn More</a>
                </div>
              </td>
              <td>{{ formatCurrency(ranking.winnings) }}</td>
            </tr>
          </tbody>
        </table>
        <div class="text-center py-5 px-2 mb-2" v-else>
          <div class="h4 text-muted">
            Sorry, there are no results for this season.
          </div>
        </div>
      </div>
      <div class="ion-margin-top" v-if="rankings.length && hasMore">
        <ion-button
          expand="block"
          size="small"
          fill="outline"
          :disabled="busy"
          @click="getRankings"
        >
          Load More
        </ion-button>
      </div>
    </ion-card-content>
  </ion-card>
</template>

<script>
import { IonCard, IonCardContent, IonIcon, IonButton } from "@ionic/vue";
import axios from "axios";
import { radioButtonOff, trendingUp, time } from "ionicons/icons";
import session from "../modules/session";

export default {
  props: {
    showStats: {},
    leagueId: {},
    showHeader: { default: true },
  },
  computed: {
    isUpgraded() {
      return this.showStats && false;
    },
    userId() {
      return session.userData.value.id;
    },
    seasonId() {
      return 5; // not yet implemented
    },
    limit() {
      // If it's the frontpage leaderboard, there's no showStats, so we can show less
      if (!this.showStats) {
        //return 5
      }

      // If we already have results bigger then limit, we can grab more results
      if (this.rankings.length > this.limit) {
        return 25;
      }

      return 10;
    },
    myPlace() {
      return this.myRanking.position;
    },
    me() {
      return this.myRanking;
    },
  },
  components: {
    IonCard,
    IonCardContent,
    IonIcon,
    IonButton,
  },
  mounted() {
    this.getMyRanking();
    this.getRankings();
  },
  methods: {
    formatCurrency(value) {
      return parseFloat(value).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    getMyRanking() {
      let withStats = this.isUpgraded ? "/1" : "";
      return axios
        .get(
          `/rankings/${this.leagueId}/season/${this.seasonId}${withStats}?me=1`
        )
        .then((response) => {
          this.myRanking = response.data[0];
        });
    },
    getRankings() {
      this.busy = true;
      let withStats = this.isUpgraded ? "/1" : "";
      return axios
        .get(
          `/rankings/${this.leagueId}/season/${this.seasonId}${withStats}?limit=${this.limit}&offset=${this.rankings.length}`
        )
        .then((response) => {
          const newRankings = Object.values(response.data);
          for (let ranking of newRankings) {
            this.rankings.push(ranking);
          }
          if (newRankings.length == 0) {
            this.hasMore = false;
          }
          this.hasRankings = true;
          this.busy = false;
          //   setTimeout(() => {
          //     jQuery('[data-toggle="tooltip"]').tooltip({
          //       boundary: "window",
          //     });
          //   }, 0);
        });
    },
  },
  data: () => ({
    offset: 0,
    learnUrl: "/todo",
    busy: true,
    hasRankings: false,
    rankings: [],
    hasMore: true,
    myRanking: {
      user: {},
    },
  }),
  setup() {
    return {
      radioButtonOff,
      trendingUp,
      time,
    };
  },
};
</script>
